import { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import Nav from "../Components/Nav";


import {
    ChakraProvider,
    Box as CBox,
} from '@chakra-ui/react'


import { State } from '../interfaces/State';
import { api_host } from '../global_vars/api_host';
import UsersTable from '../Components/UsersTable';
import "../Scss/super-admin.scss"
import SimpleSidebar from '../Components/SideBar';

function ManageUsers(props){

    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [role, setRole] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState<any>();
    const history = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${api_host}/token`);
            setToken(response.data.accessToken);
            const decoded: any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        } catch (error: any) {
            if (error.response) {
                history("/");
            }
        }
    }


    useEffect(() => {
        refreshToken();
    }, []);



    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${api_host}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded: any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    let state: State = {
        showSuperAdminBoard: false,
        showUserBoard: false,
    }

    if (role) {
        state = {
            showSuperAdminBoard: role === 'super-admin',
            showUserBoard: role === 'user',
        };
    }


    return(
        <ChakraProvider>

            {state.showSuperAdminBoard && (
                <CBox>
                    <SimpleSidebar>

                        <Nav name={name} role={role} id={id}/>
                        <CBox marginTop={20}>
                            <UsersTable id={id} role={role}/>
                        </CBox>

                    </SimpleSidebar>
                </CBox>
            )}

            {state.showUserBoard && (
                <CBox>
                    You are very naughty {name}, you don't have any permissions to be here!!!
                </CBox>
            )}

        </ChakraProvider>
    );
}



export default ManageUsers;