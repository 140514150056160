


import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    FormHelperText,
    FormErrorMessage,
    Kbd,
  } from '@chakra-ui/react';


import { Form, Formik } from 'formik';


import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { api_host } from '../global_vars/api_host';
import "../Scss/login.scss";


{
    /*
        `Login.tsx`
        Here is where the user should be redirected when they first enter the
    website or don't have any cookies that show they've logged in before.
        This page should only 

    */
}

  


export default function SimpleCard() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const history = useNavigate();


    const handleEmailChange = (e:any) => setEmail(e.target.value);
    const handlePassChange = (e:any) => setPassword(e.target.value);

    const isErrorEmail: boolean = email === '';
    const isErrorPass: boolean = password === '';

    const Auth = async (e:any) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${api_host}/login`, {
                email: email,
                password: password
            });
            const newUser = response.data.newUser;
            history("/projects", {
                state: {
                    newUser: newUser,
                }
            });
        } catch (error:any) {
            if (error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }


    const doNothing = () => {

    }


    return (
        <div>
            <Flex
                minH={'100vh'}
                align={'center'}
                justify={'center'}
                bg={useColorModeValue('gray.50', 'gray.800')}
                className="layered-steps">
                <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading 
                        fontSize={'4xl'}
                        bgGradient="linear(to-tr, blue.300, blue.400, blue.500, blue.600)"
                        backgroundClip='text'
                        >
                            Sign in to your account
                    </Heading>
                    <Text fontSize={'lg'} color={'gray.600'}>
                    to enjoy all of our cool 
                    <Popover>
                        <PopoverTrigger>
                            <Link color={'blue.400'}> features</Link>
                        </PopoverTrigger>
                            <PopoverContent textColor='white'>
                            <PopoverArrow />
                            <PopoverCloseButton />
                                <PopoverHeader>
                                    ???
                                </PopoverHeader>
                                <PopoverBody>
                                    You don't know 'em ?!
                                </PopoverBody>
                        </PopoverContent>
                    </Popover> ✌️
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}
                    bgGradient="linear(to-tr, gray.600, gray.700)">
                    <Formik 
                    onSubmit={doNothing}
                    initialValues={{}}>
                        {(props:any) => (
                            <Stack spacing={4}>
                                <Box fontSize={15} color="red.300" className="Error-message">
                                    <p><Kbd>{msg}</Kbd></p>
                                </Box>

                                <Form>
                                    <FormControl isInvalid={isErrorEmail} id="email-login">
                                        <FormLabel id="email">Email</FormLabel>
                                        <Input
                                            placeholder='callthemanager@karen.com'
                                            type="email"
                                            value={email}
                                            onChange={handleEmailChange} />
                                        {!isErrorEmail ? (
                                            <FormHelperText>
                                                Enter your email
                                            </FormHelperText>
                                        ) : (
                                            <FormErrorMessage>
                                                Email is required.
                                            </FormErrorMessage>
                                        )}
                                    </FormControl>

                                    <FormControl isInvalid={isErrorPass} id="password">
                                        <FormLabel id="password">Password</FormLabel>
                                        <Input
                                            type="password"
                                            placeholder='can I speak to your password manager?'
                                            value={password}
                                            onChange={handlePassChange} />
                                        {!isErrorPass ? (
                                            <FormHelperText>
                                                Enter your password
                                            </FormHelperText>
                                        ) : (
                                            <FormErrorMessage>
                                                Password is required.
                                            </FormErrorMessage>
                                        )}
                                    </FormControl>
                                    <Stack spacing={2}>

                                        {/* <RLink> */}
                                        <Button
                                            bg={'blue.400'}
                                            color={'white'}
                                            _hover={{
                                                bgGradient: "linear(to-r, blue.300, blue.400, blue.500)",
                                                transition: 0.5,
                                            }}
                                            bgGradient="linear(to-r, blue.400, blue.500, blue.600)"
                                            width="300px"
                                            isLoading={props.isSubmitting}
                                            type="submit"
                                            onClick={Auth}
                                            maxWidth='100%'
                                            w="100%"
                                            marginTop={5}>
                                            Sign in
                                        </Button>


                                        {/* </RLink> */}

                                    </Stack>
                                </Form>
                            </Stack>
                        )}
                        
                    </Formik>
                </Box>
                </Stack>
            </Flex>


        </div>
    );
  }