import { useState, useEffect, useMemo, useCallback } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import {
    Center,
    useToast,
    Box as CBox,
} from '@chakra-ui/react'

import MaterialReactTable, {
    MRT_ColumnDef, MRT_Row, MaterialReactTableProps,
    MRT_Cell,
} from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { User } from '../interfaces/User';
import { roles } from '../interfaces/Roles';
import { CreateUser } from '../interfaces/CreateUser';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { api_host } from '../global_vars/api_host';
import { blue, lightBlue } from '@mui/material/colors';



export default function UsersTable(props){
    const { id, role } = props;

    const [users, setUsers] = useState<User[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    // const [lastLogin, setLastLogin] = useState('');
    // const [newUser, setNewUser] = useState('');
    // const [user, setUser] = useState('');
    const toast = useToast();


    useEffect(() => {
        getUsers();
    }, []);




    const axiosJWT = axios.create();


    const getUsers = async (): Promise<any> => {
        if (role === 'super-admin') {

            if(!users.length){
                setIsLoading(true);
            }else{
                setIsRefetching(true);
            }

            try{
                const response = await axiosJWT.get(`${api_host}/users`);
                setUsers(response.data.results);
            }catch(error: any){
                setIsError(true);
                console.error(error);
                return;
            }
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);

        }
    }

    const validateRequired = (value: string) => !!value.length;
    const validateEmail = (email: string) =>
        !!email.length &&
        email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});

    const handleCreateNewRow = async (values: CreateUser) => {
        //users.push(values);
        const response = await axiosJWT.post(`${api_host}/create/user`, {
            name: values.name,
            email: values.email,
            password: values.password,
            role: values.role,
        });



        if (response.status === 200) {
            toast({
                title: 'Created User',
                description: response.data.msg,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            setUsers([...users]);
            getUsers();
        }
        else {
            toast({
                title: 'Error',
                description: response.data.msg,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    };

    const handleSaveRowEdits: MaterialReactTableProps<User>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                users[row.index] = values;
                const response = await axiosJWT.put(`${api_host}/change/user/?id=${values.id}`, {
                    name: values.name,
                    email: values.email,
                    role: values.role,
                });
                if (response.status === 200) {
                    toast({
                        title: 'Updated user',
                        description: response.data.msg,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    setUsers([...users]);
                    getUsers();
                }
                else {
                    toast({
                        title: 'Error',
                        description: response.data.msg,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                exitEditingMode(); //required to exit editing mode and close modal
            }
        };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        async (row: MRT_Row<User>) => {
            if (
                !window.confirm(`Are you sure you want to delete ${row.getValue('name')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            const response = await axiosJWT.delete(`${api_host}/delete/user/?id=${row.getValue('id')}&curr_user_id=${id}`);
            if (response.status === 200) {
                toast({
                    title: 'Deleted user',
                    description: response.data.msg,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
                users.splice(row.index, 1);
                setUsers([...users]);
                getUsers();                
            }
            else {
                toast({
                    title: 'Error',
                    description: response.data.msg,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        },
        [users, id, role],
    );

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<User>,
        ): MRT_ColumnDef<User>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo<MRT_ColumnDef<User>[]>(
        () => [
            {
                accessorKey: "id",
                header: 'Id',
                enableEditing: false, //disable editing on this column
            },
            {
                accessorKey: 'name',
                header: 'Name',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                size: 255,
            },
            {
                accessorKey: 'email',
                header: "Email",
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                enableClickToCopy: true,
                size: 255,
            },
            {
                accessorKey: 'role',
                header: "Role",
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: roles.map((role) => (
                        <MenuItem key={role} value={role}>
                            {role}
                        </MenuItem>
                    )),
                },
                size: 255,
            },
            {
                accessorKey: 'createdAt',
                header: 'Created At',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
            },
            {
                accessorKey: 'updatedAt',
                header: 'Updated At',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
            },
            {
                accessorKey: 'last_login',
                header: 'Last Login',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
            }
        ],
        [getCommonEditTextFieldProps],
    );

    const CreateColumns = useMemo<MRT_ColumnDef<CreateUser>[]>(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                size: 255,
            },
            {
                accessorKey: 'email',
                header: "Email",
                size: 255,
            },
            {
                accessorKey: 'password',
                header: "Password",
                size: 255,
            },
            {
                accessorKey: 'role',
                header: "Role",
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: roles.map((role) => (
                        <MenuItem key={role} value={role}>
                            {role}
                        </MenuItem>
                    )),
                },
                size: 255,
            },
        ],
        [],
    );


    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const muiTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: lightBlue[900],
            },
            secondary: {
                main: blue[600],
            },
        },
    });

    const handleExportRows = (rows: MRT_Row<User>[]) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(users);
    };

    return(
        <ThemeProvider theme={muiTheme}>
            <CBox>
                <Center>
                    {/* <CBox display='block'> */}
                    <CBox
                        w='90%'
                        zIndex={0}
                    >
                        <MaterialReactTable
                            columns={columns}
                            data={users}
                            enableRowSelection
                            enableStickyHeader
                            enableStickyFooter
                            positionToolbarAlertBanner='bottom'
                            editingMode="modal" //default
                            enableColumnOrdering
                            enableEditing
                            onEditingRowSave={handleSaveRowEdits}
                            onEditingRowCancel={handleCancelRowEdits}
                            renderRowActions={({ row, table }) => (
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Tooltip arrow placement="left" title="Edit">
                                        <IconButton onClick={() => table.setEditingRow(row)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="right" title="Delete">
                                        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )}
                            renderTopToolbarCustomActions={({ table }) => (
                                <Box
                                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                >

                                    <Button
                                        color="secondary"
                                        onClick={() => setCreateModalOpen(true)}
                                        variant="contained"
                                    >
                                        Create New Account
                                    </Button>

                                    <Button
                                        color="primary"
                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                        onClick={handleExportData}
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export All Data
                                    </Button>
                                    <Button
                                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                                        //export all rows, including from the next page, (still respects filtering and sorting)
                                        onClick={() =>
                                            handleExportRows(table.getPrePaginationRowModel().rows)
                                        }
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export All Rows
                                    </Button>
                                    <Button
                                        disabled={table.getRowModel().rows.length === 0}
                                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                        onClick={() => handleExportRows(table.getRowModel().rows)}
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export Page Rows
                                    </Button>
                                    <Button
                                        disabled={
                                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                        }
                                        //only export selected rows
                                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export Selected Rows
                                    </Button>
                                </Box>
                            )}

                            muiToolbarAlertBannerProps={
                                isError
                                    ? {
                                        color: 'error',
                                        children: 'Error loading data',
                                    }
                                    : undefined
                            }

                            state={{
                                isLoading,
                                showAlertBanner: isError,
                                showProgressBars: isRefetching,
                            }}
                        />
                        <CreateNewAccountModal
                            CreateColumns={CreateColumns}
                            open={createModalOpen}
                            onClose={() => setCreateModalOpen(false)}
                            onSubmit={handleCreateNewRow}
                        />
                    </CBox>


                    {/* </CBox> */}
                </Center>

            </CBox>

        </ThemeProvider>

    );
}


interface CreateModalProps {
    CreateColumns: MRT_ColumnDef<CreateUser>[];
    onClose: () => void;
    onSubmit: (values: CreateUser) => void;
    open: boolean;
}



export const CreateNewAccountModal = ({
    open,
    CreateColumns,
    onClose,
    onSubmit,
}: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
        CreateColumns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Account</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {CreateColumns.map((column) => (
                            <TextField
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            />
                        ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Create New Account
                </Button>
            </DialogActions>
        </Dialog>
    );
};
