import { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { api_host } from '../../global_vars/api_host';

import "../../Scss/admin-dashboard.scss";
import EmployeeCard from './BestEmployeesCard'

import { 
    Box,
    Container,
    Heading,
    Text, 
    Flex,
    SimpleGrid,
    GridItem,
    Center,
} from '@chakra-ui/react';

export default function BestEmployeesBackground(props){

    const [showcase, setShowcase] = useState<any[]>([]);


    useEffect(() => {
      getShowcase();
    }, []);

    const axiosJWT = axios.create();


    const getShowcase = async() => {
      try{
        const response = await axiosJWT.get(`${api_host}/analytics/showcase`);

        setShowcase(response.data);
      }catch(error: any){
        console.log(error);
      }
    }

    return(
        <>
            <Box className="dashboard-box" w="100%">
                {/*<Box className="ebackground-lefthand-text" textAlign='left'>
                    <Heading className="ebackground-heading" size="lg">
                        Best employees
                    </Heading>
                    <br/>
                    <Text maxW="md">
                        A comprehensive list of the best employees off all time, taking into consideration orders completed, types of order completed, etc.
                    </Text>
                    */}

                    <Box>
                      <Center>
                        <Heading mb={5} fontSize="30px" color="gray.400">
                          Best employee showcase
                        </Heading>
                      </Center>
                    </Box>
                    <SimpleGrid minChildWidth={200} spacing={10}>                  
                        {showcase.map((res) => (
                          <GridItem>
                            <EmployeeCard name={res.name} role={res.role} amount={res.amount_count} orders={res.orders_no}/>
                          </GridItem>
                        ))}
                  </SimpleGrid>
                {/*</Box>*/}

            </Box>
        </>
    );
}
