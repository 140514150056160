import {
  ChakraProvider,
  Box,
} from "@chakra-ui/react"
import Login from './Pages/Login'
// import './Sass/Dashboard.scss';
// import "./Sass/ProjectCards.scss";


import { extendTheme, type ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const theme = extendTheme({ config });

function App(){
  return(
    <ChakraProvider theme={theme}>
      {/* <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
        </Grid>

        <Box className="">

        </Box>
      </Box> */}

      <Box 
        className="App"
        textAlign="center"
        fontSize="xl">
          <Box className="Login">
            <Login />
          </Box>
      </Box>
    </ChakraProvider>
  );
}


export default App;
