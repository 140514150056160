import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { api_host } from '../global_vars/api_host';

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Center,
  Heading,
  Box,
} from '@chakra-ui/react';
import {  FcApproval  } from 'react-icons/fc';
import '../Scss/totalOrders.scss';

function UserDoneOrders(props){
  const { id } = props;

  const [totalDone, setTotalDone] = useState('');

  useEffect(() => {
    getTotalDone();
  }, []);

  const axiosJWT = axios.create();

  const getTotalDone = async() => {
    try{
      const response = await axiosJWT.get(`${api_host}/orders/assigned/done/?id=${id}`);

      setTotalDone(response.data.count);
    }catch(error: any){
      console.error(error);
      return;
    }
  }

  return(
    
    <>
      <Center padding={75} textAlign='center'>
          <Stat>
            <StatLabel fontSize={40}>Your orders</StatLabel>
            <Center fontSize={40}>
              <FcApproval />
            </Center>
            <br/>
            <Box className="total-orders-stat-number">
              <StatNumber>{totalDone}</StatNumber>
            </Box>
            <br/>
            <Box className="total-orders-help-text">
              <StatHelpText>
                <StatArrow type='increase' />
                how many orders you completed 
              </StatHelpText>
            </Box>
          </Stat>
      </Center>
    </>

  );
}


export default UserDoneOrders;
