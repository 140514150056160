import { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { api_host } from '../global_vars/api_host';



import {
    Center,
    ChakraProvider,
    Box as CBox,
} from '@chakra-ui/react'

import MaterialReactTable, {
    MRT_ColumnDef
} from 'material-react-table';
import {
    Box,
} from '@mui/material';
import { Order } from '../interfaces/Order';
import { ThemeProvider, createTheme,  } from '@mui/material';



function OrdersAssignedTable(props){

    const { id } = props;
    const [orders, setOrders] = useState<Order[]>([]);

    useEffect(() => {
        getOrders();
    }, [setOrders]);

    const axiosJWT = axios.create();


    const getOrders = async(): Promise<any> => {
        const response = await axiosJWT.get(`${api_host}/orders/only/assigned/users/?id=${id}`);

        setOrders(response.data);
    }

    const columns = useMemo<MRT_ColumnDef<Order>[]>(
        () => [ 
            {
                accessorKey: 'status',
                header: 'Status',
                Cell: ({ cell }) => (
                    <Box
                        component='span'
                        sx={(muiTheme) => ({
                            backgroundColor:
                                cell.getValue<string>() === 'ongoing'
                                    ? muiTheme.palette.warning.dark
                                    : muiTheme.palette.success.dark,
                            borderRadius: '0.50rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue<string>()}
                    </Box>
                )
            },
            {
                accessorKey: "order_no",
                header: 'Order No.',
                enableEditing: false,
                enableSorting: false,
                enableClickToCopy: true,
            },
            {
                accessorKey: 'order_date',
                header: 'Order Date',
            },
            {
                accessorKey: 'client',
                header: "Client",
                size: 255,
                enableClickToCopy: true,
            },
            {
                accessorKey: 'first_delivery',
                header: "First delivery",
            },
            {
                accessorKey: 'revisions',
                header: 'Revisions',
            },
            {
                accessorKey: 'final_delivery',
                header: 'Final delivery',
            },
            {
                accessorKey: 'invoice_no',
                header: 'Invoice No.',
            },
            {
                accessorKey: 'invoice_date',
                header: 'Invoice Date',
            },
            {
                accessorKey: 'payment_date',
                header: 'Payment Date',
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                enableClickToCopy: true,
            },
        ],
        [],
    );

    const muiTheme = createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: 'rgb(26, 36, 51)',
            },
        },
    });

    return(
        <>
            <ChakraProvider>
                <ThemeProvider theme={muiTheme}>
                    <Center>
                        <CBox
                            w="95%"
                            zIndex={0}
                        >

                            <MaterialReactTable
                                columns={columns}
                                data={orders}
                                enableColumnOrdering
                            />

                        </CBox>
                    </Center>
                </ThemeProvider>
            </ChakraProvider>
        </>
    );

}


export default OrdersAssignedTable;
