import { useState, useEffect } from 'react';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';

import { api_host } from '../global_vars/api_host';


function DoughnutDoneOngoing(props){

  const initialDoughnutValue = { datasets: [] };

  const [doughnutData, setDoughnutData] = useState(initialDoughnutValue);

  const getDoughnutData = async (): Promise<any> => {
    try {
      const doneOrders = await axiosJWT.get(`${api_host}/orders/done`);
      const ongoingOrders = await axiosJWT.get(`${api_host}/orders/ongoing`);
      const updatedValue: any = {
        labels: [
          'Done Orders',
          'Ongoing orders',
        ],
        datasets: [
          {
            label: 'Orders',
            data: [
              doneOrders.data.count,
              ongoingOrders.data.count,
            ],
            backgroundColor: [
              'rgb(50,205,50)',
              'rgb(255,127,80)',
            ]
          }
        ],
      }

      setDoughnutData({
        ...updatedValue,
      });


    } catch (error: any) {
      console.error(error);
      return;
    }
  } 

  useEffect(() => {
      getDoughnutData();
    }, []);

  const axiosJWT = axios.create();



  return(
    <>
      <Doughnut data={doughnutData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Done:Ongoing orders'
            }
          }
        }}
      />
    </>
  );
}


export default DoughnutDoneOngoing;
