import {
    Box,
    Heading,
    Divider,
    Text,
    Button,
    ChakraProvider,
    Center,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import ProjectCardProps from '../interfaces/ProjectCardProps';




function ProjectCard(props: ProjectCardProps) {
    const { name, description, relative_path } = props;
    return (
        <ChakraProvider>
            <Center>
                <Box
                    className="project-card-wrapper"
                    borderRadius="3xl"
                    background="#38383d"
                    boxShadow="xl"
                    _hover={{ background: "#42414d" }}
                    position="relative"
                    maxW={{
                        base: '100%',
                        md: "60%",
                        xl: "40%",
                    }}
                    w="full"
                    >

                    <Box p={5}>
                        <Heading as="h2" size="xl" pb={2} color="#00DDFF">
                            {name}
                        </Heading>

                        <Divider />
                        <br/>

                        <Box className="project-card-description">
                            <Text fontSize={["lg", "xl"]}>
                                {description}
                            </Text>
                        </Box>

                        <Box
                            paddingLeft={10} 
                            className="project-card-button">
                            
                            <Link to={relative_path}>
                                <Button size="lg" colorScheme='telegram' mt="24px">
                                    Go to project
                                </Button>
                            </Link>
                        </Box>

                    </Box>

                </Box>
            </Center>
        </ChakraProvider>
    );
}


export default ProjectCard;
