import { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { api_host } from '../global_vars/api_host';



import {
    Center,
    ChakraProvider,
    Box as CBox,
} from '@chakra-ui/react'

import MaterialReactTable, {
    MRT_ColumnDef
} from 'material-react-table';
import { FrequentCustomer } from '../interfaces/FrequentCustomer';
import { ThemeProvider, createTheme,  } from '@mui/material';



function FrequentCustomersAnalytics(props){
  const [frequent, setFrequent] = useState<FrequentCustomer[]>([]);

  useEffect(() => {
    getFrequent();
  }, []);

  const axiosJWT = axios.create();

  const getFrequent = async(): Promise<any> => {
    const response = await axiosJWT.get(`${api_host}/analytics/frequent`);

    setFrequent(response.data);
  }

  const columns = useMemo<MRT_ColumnDef<FrequentCustomer>[]>(
    () => [
      {
        accessorKey: 'client',
        header: 'Client',
      },
      {
        accessorKey: 'count',
        header: 'No. of orders',
      },
    ],
    [],
  );

  const muiTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: 'rgb(26, 36, 51)',
      },
    },
  });

  return(
    <>
      <ChakraProvider>
        <ThemeProvider theme={muiTheme}>
          <Center>
            <CBox w="100%" zIndex={0}>
              <MaterialReactTable 
                columns={columns}
                data={frequent}
                initialState={{ density: 'compact' }}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                enableGlobalFilter={false}
                enableHiding={false}
                enableColumnFilters={false}
                enableRowNumbers={true}
              />
            </CBox>
          </Center>
        </ThemeProvider>
      </ChakraProvider>
    </>
  );
}


export default FrequentCustomersAnalytics;
