import { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import Nav from "../Components/Nav";
import {
    ChakraProvider,
    Box as CBox,
    SimpleGrid,
    GridItem,
    Heading,
    Center,
    Flex,
} from '@chakra-ui/react'
import { State } from '../interfaces/State';
import { api_host } from '../global_vars/api_host';
import "../Scss/admin-dashboard.scss";
import SimpleSidebar from '../Components/SideBar';
import TotalOrdersAnalytics from '../Components/TotalOrdersAnalytics';
import DoughnutDoneOngoing from '../Components/DoughnutDoneOngoing';
import TotalUsersAnalytics from '../Components/TotalUsersAnalytics';
import UserDoneOrders from '../Components/UserDoneOrders';
import OrdersMultiAxisChartAnalytics from '../Components/OrdersMultiAxisChartAnalytics';
import TotalPaymentAmountAnalytics from '../Components/TotalPaymentAmountAnalytics';
import AverageOrderPaymentAnalytics from '../Components/AverageOrderPaymentAnalytics';
import FrequentCustomersAnalytics from '../Components/FrequentCustomersAnalytics';
import NeedyCustomersAnalytics from '../Components/NeedyCustomersAnalytics';
import BestEmployeesCard from '../Components/Best_Employees/BestEmployeesCard';
import BestEmployeesBackground from '../Components/Best_Employees/BestEmployeesBackground';


import '../Scss/bestemployeecard.scss';

function Analytics(props){
  
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [role, setRole] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState<any>();


    
    const history = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${api_host}/token`);
            setToken(response.data.accessToken);
            const decoded: any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        } catch (error: any) {
            if (error.response) {
                history("/");
            }
        }
    }


    useEffect(() => {
        refreshToken();
    },[]);



    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${api_host}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded:any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    

    let state: State = {
        showSuperAdminBoard: false,
        showUserBoard: false,
    }

    if (role) {
        state = {
            showSuperAdminBoard: role === 'super-admin',
            showUserBoard: role === 'user',
        };
    }

  return(
    <>
      <ChakraProvider>
        {state.showSuperAdminBoard && (
          <SimpleSidebar>
            <Nav id={id} name={name} role={role} />

            <SimpleGrid mt={5} minChildWidth={250} spacing={50} className="dashboard-row"> 
              <GridItem className="dashboard-box">  
                <DoughnutDoneOngoing />
              </GridItem>
              <GridItem className="dashboard-box" >
                <TotalOrdersAnalytics />
              </GridItem>


              <GridItem className="dashboard-box" >
                <TotalUsersAnalytics />
              </GridItem>

              <GridItem className="dashboard-box" >
                <UserDoneOrders id={id} />
              </GridItem> 
            </SimpleGrid>

            <CBox display='flex' w={['100%', '100%', '100%']} h={[350, 450, 550]} className="dashboard-box">
              <OrdersMultiAxisChartAnalytics /> 
            </CBox>

            
            <SimpleGrid mt={5} minChildWidth={250} spacing={50} className="dashboard-row"> 
              <GridItem className="dashboard-box">
                <TotalPaymentAmountAnalytics />
              </GridItem>
              
              <GridItem className="dashboard-box">
                <AverageOrderPaymentAnalytics />
              </GridItem>
            </SimpleGrid>

            <SimpleGrid mt={5} minChildWidth={250} spacing={50} className="dashboard-row">
              <GridItem className="dashboard-box">
                <Heading fontSize={20}>
                  <Center>Most frequent customers</Center>
                </Heading>
                <br/>
                <FrequentCustomersAnalytics />
              </GridItem>

              <GridItem className="dashboard-box">
                <Heading fontSize={20}>
                  <Center>Needy customers</Center>
                </Heading>
                <br/>
                <NeedyCustomersAnalytics />
              </GridItem>
            </SimpleGrid>
            <BestEmployeesBackground /> 
          </SimpleSidebar>


          
        )}

        {state.showUserBoard && (
          <Nav id={id} name={name} role={role} />
        )}
      </ChakraProvider> 
    </>
  );
}


export default Analytics;
