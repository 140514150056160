import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { api_host } from '../global_vars/api_host';

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Center,
  Box,
} from '@chakra-ui/react';
import {  FcReading  } from 'react-icons/fc';
import '../Scss/totalOrders.scss';

function TotalUsersAnalytics(props){
  const [totalUsers, setTotalUsers] = useState('');

  useEffect(() => {
    getTotalUsers();
  }, []);

  const axiosJWT = axios.create();

  const getTotalUsers = async() => {
    try{
      const response = await axiosJWT.get(`${api_host}/users/total`);

      setTotalUsers(response.data.count);
    }catch(error: any){
      console.error(error);
      return;
    }
  }

  return(
    
    <>
      <Center padding={75} textAlign='center'>
          <Stat>
            <StatLabel fontSize={40}>Total Users</StatLabel>
            <Center fontSize={40}>
              <FcReading />
            </Center>
            <br/>
            <Box className="total-orders-stat-number">
              <StatNumber>{totalUsers}</StatNumber>
            </Box>
            <br/>
            <Box className="total-orders-help-text">
              <StatHelpText>
                <StatArrow type='increase' />
                includes super-admins and users 
              </StatHelpText>
            </Box>
          </Stat>
      </Center>
    </>

  );
}


export default TotalUsersAnalytics;
