import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { api_host } from '../global_vars/api_host';

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Center,
  Box,
} from '@chakra-ui/react';
import { FcMoneyTransfer } from 'react-icons/fc';


function TotalPaymentAmountAnalytics(props){
  const [totalAmount, setTotalAmount] = useState('');

  useEffect(() => {
    getTotalAmount();
  }, []);

  const axiosJWT = axios.create();

  const getTotalAmount = async() => {
    try{
      const response = await axiosJWT.get(`${api_host}/orders/payment/total`);

      setTotalAmount(response.data.count);
    }catch(error: any){
      console.error(error);
      return;
    }
  }

  return(
    <>
      <Stat>
        <Box display="flex" className="total-amount-title">
            <StatLabel fontSize={20}>Payment this year</StatLabel>
        </Box>
        <Box display="flex" style={{ flexDirection: 'row-reverse' }}>
          <FcMoneyTransfer size={70} />
        </Box>
        <Box mt={-65} display="flex" className="total-amount-stat-number">
          <StatNumber display="flex" marginLeft={3} fontSize={30}><b>${totalAmount}</b>
          </StatNumber>
        </Box>
        <Box mt={2} className="total-amount-help-text">
          <StatHelpText>
            <StatArrow type='increase'/>
            gross income
          </StatHelpText>
        </Box>
      </Stat>
    </>
  );
}

export default TotalPaymentAmountAnalytics;
