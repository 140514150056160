import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    ChakraProvider,
    useDisclosure,
    Button,
    Center,
    Text,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    FormControl,
    Input,
    FormHelperText,
    FormErrorMessage,
    Kbd,
    Divider,
} from '@chakra-ui/react'


import{
    MutableRefObject,
    useState,
    useRef,
} from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api_host } from '../global_vars/api_host';





export default function FirstLogin(props){
    const { name, role } = props;
    const { isOpen: isOpenPrompt, onClose: onClosePrompt } = useDisclosure({ defaultIsOpen: true});
    //`defaultIsOpen: false` is only for clarity, it actually defaults to false
    const { isOpen: isOpenChange, onOpen: onOpenChange, onClose: onCloseChange } = useDisclosure({ defaultIsOpen: false});
    const [msg, setMsg] = useState('');
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [passMsg, setPassMsg] = useState('');
    const cancelRef = useRef() as MutableRefObject<HTMLButtonElement>;
    const navigate = useNavigate();

    const handleNewPassChange = (e: any) => setNewPass(e.target.value);
    const handleOldPassChange = (e: any) => setOldPass(e.target.value);
    const isErrorNewPass = newPass === '';
    const isErrorOldPass = oldPass === '';

    const changePass = async () => {
        try{
            await axios.put(`${api_host}/change/pass`, {
                oldPassword: oldPass,
                newPassword: newPass,
            });
            navigate("/");
        }catch(error: any){
            console.log(error);
            setPassMsg(error.response.data.msg);
        }
    }
    
    const nothing = () => {
        setMsg('You have to change your password !');
    }

    return(
        <ChakraProvider>
            <Box className="first-login-card-wrapper">
                <Modal onClose={nothing} isOpen={isOpenPrompt} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Hey {name}, Maybe change your password?</ModalHeader>
                        <ModalCloseButton />
                        <Divider />
                        <ModalBody>
                            Your current password is probably not what you want, so you should probably change it to your own preferrences. Click the 
                            button below to change your password so you can continue your <Kbd>{role}</Kbd> duties.

                            <Center mt={5}>
                                <Text textColor='red.400'>
                                    {msg}
                                </Text>
                            </Center>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onOpenChange} colorScheme='purple'>Change Password</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <AlertDialog
                    motionPreset='slideInBottom'
                    isOpen={isOpenChange}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseChange}
                    isCentered>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Change Password
                            </AlertDialogHeader>

                            <AlertDialogBody fontWeight="bold">
                                Proceed with caution like you are being followed by a Karen. You can't undo this!
                                <br />
                                <br />

                                <p>
                                    Old Password
                                </p>
                                <FormControl isInvalid={isErrorOldPass} id="oldpass-change">
                                    <Input
                                        placeholder="here's the password manager"
                                        value={oldPass}
                                        onChange={handleOldPassChange}
                                        type="password" />
                                    {!isErrorOldPass ? (
                                        <FormHelperText>
                                            Enter your old password
                                        </FormHelperText>
                                    ) : (
                                        <FormErrorMessage>
                                            Old password is required.
                                        </FormErrorMessage>
                                    )}

                                    <br />

                                    <p>
                                        New Password
                                    </p>
                                </FormControl>
                                <FormControl isInvalid={isErrorNewPass} id="newpass-change">
                                    <Input
                                        placeholder="here's the password manager"
                                        value={newPass}
                                        onChange={handleNewPassChange}
                                        type="password" />
                                    {!isErrorNewPass ? (
                                        <FormHelperText>
                                            Enter your new password
                                        </FormHelperText>
                                    ) : (
                                        <FormErrorMessage>
                                            New password is required.
                                        </FormErrorMessage>
                                    )}
                                </FormControl>

                                <br />

                                <Center>
                                    {passMsg}
                                </Center>

                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onCloseChange}>
                                    Cancel
                                </Button>
                                <Button colorScheme='red' ml={3} ref={cancelRef} onClick={changePass}>
                                    Change
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Box>

        </ChakraProvider>
    );
}