/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo, useCallback } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api_host } from '../global_vars/api_host';

import {
    Center,
    ChakraProvider,
    useToast,
    Box as CBox,
    Divider,
} from '@chakra-ui/react'

import MaterialReactTable, {
    MRT_ColumnDef, MRT_Row, MaterialReactTableProps,
    MRT_Cell,
} from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { Order } from '../interfaces/Order';
import { CreateOrder, orderNrPrefix } from '../interfaces/CreateOrder';
import { ThemeProvider, createTheme } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { State } from '../interfaces/State';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import GetUserId from './GetUserId';
import { status } from '../interfaces/Status';
import GetAssignedTo from './GetAssignedTo';
import { User } from '../interfaces/User';
import { Theme, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { blue, lightBlue } from '@mui/material/colors';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


function MultiSelect({users, onBlur}){
    const theme = useTheme();
    const [assignedTo, setAssignedTo] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof assignedTo>) => {
        const {
            target: { value },
        } = event;
        setAssignedTo(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    return (
        <Select
            labelId="demo-multiple-chip-label"
            label="Assigned To"
            key="assigned_to"
            id="demo-multiple-chip"
            multiple
            value={assignedTo}
            onChange={handleChange}
            onBlur={onBlur}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                        <Chip key={value} label={value} />
                    ))}
                </Box>
            )}
            MenuProps={MenuProps}
        >

            <MenuItem disabled value="">
                <em>Assign User</em>
            </MenuItem>
            {users.length && users.map((user) => {
                return (
                    <MenuItem
                        key={user.name}
                        value={user.name}
                        style={getStyles(user.name, assignedTo, theme)}
                    >
                        {user.name}({user.id})
                    </MenuItem>
                );
            })}
        </Select>
    );
}

function OrdersTable(props){
    const { id, role } = props;

    const [orders, setOrders] = useState<Order[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [msg, setMsg] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);

    const history = useNavigate();
    const toast = useToast();


    useEffect(() => {
        getOrders();
        getUsers();
        // isUserNew();
    }, [setUsers, setOrders]);




    const axiosJWT = axios.create();

    const getOrders = async (): Promise<any> => {
        if (role === 'super-admin') {
            if(!orders.length){
                setIsLoading(true);
            }
            else{
                setIsRefetching(true);
            }


            try{
              const response = await axiosJWT.get(`${api_host}/orders`);
              setOrders(response.data.results);
            }catch(error: any){
              setIsError(true);
              console.log(error);
              return;
            }
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }

    const getUsers = async (): Promise<any> => {
        if (role === 'super-admin') {
            const response = await axiosJWT.get(`${api_host}/users`);
            setUsers(response.data.results);
        }
    }

    const validateRequired = (value: string) => !!value.length;


    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});

    const handleCreateNewRow = async (values: CreateOrder) => {
        //orders.push(values);
        const response = await axiosJWT.post(`${api_host}/create/order`, {
            order_no_prefix: values.order_no.toLocaleLowerCase(),
            order_date: values.order_date,
            description: values.description,
            client: values.client,
            first_delivery: values.first_delivery,
            revisions: values.revisions,
            final_delivery: values.final_delivery,
            invoice_date: values.invoice_date,
            payment_date: values.payment_date,
            amount: values.amount,
            created_by: id,
            updated_by: id,
            assigned_to: values.assigned_to,
        });

        setMsg(response.data.msg);
        setSuccess(response.data.success);

        if (response.status === 200) {
            toast({
                title: 'Created order',
                description: response.data.msg,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setOrders([...orders]);
            getOrders();
        }
        else {
            toast({
                title: 'Error',
                description: response.data.msg,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    };

    const handleSaveRowEdits: MaterialReactTableProps<Order>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                orders[row.index] = values;
                const response = await axiosJWT.put(`${api_host}/change/order/?id=${values.id}`, {
                    status: values.status,
                    description: values.description,
                    order_date: values.order_date,
                    client: values.client,
                    first_delivery: values.first_delivery,
                    revisions: values.revisions,
                    final_delivery: values.final_delivery,
                    invoice_no: values.invoice_no,
                    invoice_date: values.invoice_date,
                    payment_date: values.payment_date,
                    amount: values.amount,
                    updated_by: id,
                    assigned_to: values.assigned_to,
                });
                setMsg(response.data.msg);
                setSuccess(response.data.success);
                if (response.status === 200) {
                    toast({
                        title: 'Updated order',
                        description: response.data.msg,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    setOrders([...orders]);
                    getOrders();
                }
                else {
                    toast({
                        title: 'Error',
                        description: response.data.msg,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                exitEditingMode(); //required to exit editing mode and close modal
            }
        };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        async (row: MRT_Row<Order>) => {
            if (
                !window.confirm(`Are you sure you want to delete order with id: ${row.getValue('id')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            const response = await axiosJWT.delete(`${api_host}/delete/order/?id=${row.getValue('id')}`);
            setMsg(response.data.msg);
            setSuccess(response.data.success);
            if (response.status === 200) {
                toast({
                    title: 'Deleted order',
                    description: response.data.msg,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
                orders.splice(row.index, 1);
                setOrders([...orders]);
                getOrders();
            }
            else {
                toast({
                    title: 'Error',
                    description: response.data.msg,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        },
        [orders, id, role],
    );

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<Order>,
        ): MRT_ColumnDef<Order>['muiTableBodyCellEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );




    const columns = useMemo<MRT_ColumnDef<Order>[]>(
        () => [
            {
                accessorKey: 'id',
                header: "Id",
                enableEditing: false,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                muiTableBodyCellEditTextFieldProps: {
                    select: true,
                    children: status.map((status) => (
                        <MenuItem key={status} value={status}>
                            {status}
                        </MenuItem>
                    ))
                },
                Cell: ({cell}) => (
                    <Box
                        component='span'
                        sx={(muiTheme) => ({
                            backgroundColor:
                                cell.getValue<string>() === 'ongoing'
                                    ? muiTheme.palette.warning.dark
                                    : muiTheme.palette.success.dark,
                            borderRadius: '0.50rem',
                            color: '#fff',
                            maxWidth: '9ch',
                            p: '0.25rem',
                        })}
                    >
                        {cell.getValue<string>()}
                    </Box>
                )
            },
            {
              accessorKey: 'description',
              header: "Description",
              enableEditing: true,
            },
            {
                accessorKey: "order_no",
                header: 'Order No.',
                enableEditing: false,
                enableSorting: false,
                enableClickToCopy: true,
            },
            {
                accessorKey: 'order_date',
                header: 'Order Date',
            },
            {
                accessorKey: 'client',
                header: "Client",
                size: 255,
                enableClickToCopy: true,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'first_delivery',
                header: "First delivery",
            },
            {
                accessorKey: 'revisions',
                header: 'Revisions',
            },
            {
                accessorKey: 'final_delivery',
                header: 'Final delivery',
            },
            {
                accessorKey: 'invoice_no',
                header: 'Invoice No.',
            },
            {
                accessorKey: 'invoice_date',
                header: 'Invoice Date',
            },
            {
                accessorKey: 'payment_date',
                header: 'Payment Date',
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
                enableClickToCopy: true,
            },
            {
                accessorKey: 'created_at',
                header: 'Created At',
                enableClickToCopy: true,
            },
            {
                accessorKey: 'assigned_to',
                header: 'Assigned To',
                Edit: ({cell, row, column, table}) => {
                    const [multiUsers, setMultiUsers] = useState<User[]>([]);
                    
                    useEffect(() => {
                        getMultiUsers();
                    }, []);

                    const getMultiUsers = async() => {
                        const response = await axiosJWT.get(`${api_host}/users`);
                        setMultiUsers(response.data.results);
                    }

                    return <MultiSelect users={multiUsers} onBlur={(e) => row._valuesCache['assigned_to'] = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value} />
                }
            }
        ],
        [],
    );

    const CreateColumns = useMemo<MRT_ColumnDef<CreateOrder>[]>(
        () => [
            {
                /*It's actually order number prefix*/
                accessorKey: "order_no",
                header: 'Order No. Prefix',

            },
            {
                accessorKey: 'order_date',
                header: 'Order date',
            },
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                accessorKey: 'client',
                header: "Client",
                size: 255,
            },
            {
                accessorKey: 'first_delivery',
                header: "First delivery",
            },
            {
                accessorKey: 'revisions',
                header: 'Revisions',
            },
            {
                accessorKey: 'final_delivery',
                header: 'Final delivery',
            },
            {
                accessorKey: 'invoice_date',
                header: 'Invoice Date',
            },
            {
                accessorKey: 'payment_date',
                header: 'Payment Date',
            },
            {
                accessorKey: 'amount',
                header: 'Amount'
            },
            {
                accessorKey: 'assigned_to',
                header: 'Assigned to',
            },
        ],
        [],
    );


    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const muiTheme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: lightBlue[900],
            },
            secondary: {
                main: blue[600],
            },
        },
    });

    const handleExportRows = (rows: MRT_Row<Order>[]) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(orders);
    };


    let state: State = {
        showSuperAdminBoard: false,
        showUserBoard: false,
    }

    if (role) {
        state = {
            showSuperAdminBoard: role === 'super-admin',
            showUserBoard: role === 'user',
        };
    }

    return(
        <>
            <ChakraProvider>
                <ThemeProvider theme={muiTheme}>
                    {state.showSuperAdminBoard && (
                        <Center>
                            <CBox
                                w='95%'
                                zIndex={0}
                            >
                                <MaterialReactTable
                                    columns={columns}
                                    data={orders}
                                    enableRowSelection
                                    enableStickyHeader
                                    enableStickyFooter
                                    positionToolbarAlertBanner='bottom'
                                    editingMode="modal" //default
                                    enableColumnOrdering
                                    enableEditing
                                    onEditingRowSave={handleSaveRowEdits}
                                    onEditingRowCancel={handleCancelRowEdits}
                                    renderRowActions={({ row, table }) => (
                                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                                            <Tooltip arrow placement="left" title="Edit">
                                                <IconButton onClick={() => table.setEditingRow(row)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip arrow placement="right" title="Delete">
                                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    )}
                                    renderTopToolbarCustomActions={({ table }) => (
                                        <Box
                                            sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                        >

                                            <Button
                                                color="secondary"
                                                onClick={() => setCreateModalOpen(true)}
                                                variant="contained"
                                            >
                                                Create New Order
                                            </Button>

                                            <Button
                                                color="primary"
                                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                onClick={handleExportData}
                                                startIcon={<FileDownloadIcon />}
                                                variant="contained"
                                            >
                                                Export All Data
                                            </Button>
                                            <Button
                                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                //export all rows, including from the next page, (still respects filtering and sorting)
                                                onClick={() =>
                                                    handleExportRows(table.getPrePaginationRowModel().rows)
                                                }
                                                startIcon={<FileDownloadIcon />}
                                                variant="contained"
                                            >
                                                Export All Rows
                                            </Button>
                                            <Button
                                                disabled={table.getRowModel().rows.length === 0}
                                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                                onClick={() => handleExportRows(table.getRowModel().rows)}
                                                startIcon={<FileDownloadIcon />}
                                                variant="contained"
                                            >
                                                Export Page Rows
                                            </Button>
                                            <Button
                                                disabled={
                                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                                }
                                                //only export selected rows
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                startIcon={<FileDownloadIcon />}
                                                variant="contained"
                                            >
                                                Export Selected Rows
                                            </Button>
                                        </Box>
                                    )}
                                    renderDetailPanel={({ row }) => (
                                        <Box
                                            sx={{
                                                display: 'block',
                                                margin: 'auto',
                                                gridTemplateColumns: '1fr 1fr',
                                                width: '100%',
                                            }}
                                        >
                                            <Typography>Description: {row.original.description}</Typography>
                                            <Typography>Created By: <b><GetUserId id={row.original.created_by} /> ({row.original.created_by})</b></Typography>
                                            <Typography>Updated By: <b><GetUserId id={row.original.updated_by} /> ({row.original.updated_by})</b></Typography>
                                            <Typography>Assigned To: <b><GetAssignedTo order_id={row.original.id}/></b></Typography>
                                        </Box>
                                    )}
                                    enableHiding={true}
                                    initialState={{ columnVisibility: { assigned_to: false, description: false}}}
                                    

                                  muiToolbarAlertBannerProps={
                                    isError
                                        ? {
                                            color: 'error',
                                            children: 'Error loading data',
                                        }
                                        : undefined
                                  }

                                  state={{
                                      isLoading,
                                      showAlertBanner: isError,
                                      showProgressBars: isRefetching,
                                  }}

                                />

                                <CreateNewAccountModal
                                    CreateColumns={CreateColumns}
                                    open={createModalOpen}
                                    onClose={() => setCreateModalOpen(false)}
                                    onSubmit={handleCreateNewRow}
                                    users={users}
                                />
                            </CBox>

                            {/* <CBox
                            w='75%'
                            zIndex={0}
                        >

                           <OrdersTable />

                        </CBox> */}
                        </Center>
                    )}

                </ThemeProvider>
                
            </ChakraProvider>
        </>
    );
}


interface CreateModalProps {
    CreateColumns: MRT_ColumnDef<CreateOrder>[];
    onClose: () => void;
    onSubmit: (values: CreateOrder) => void;
    users: User[];
    open: boolean;
}




export const CreateNewAccountModal = ({
    open,
    CreateColumns,
    onClose,
    onSubmit,
    users
}: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
        CreateColumns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {} as any),
    );

    const theme = useTheme();
    const [assignedTo, setAssignedTo] = useState<string[]>([]);

    const handleChange = (event: SelectChangeEvent<typeof assignedTo>) => {
        const {
            target: { value },
        } = event;
        setValues({ ...values, ['assigned_to']: typeof value === 'string' ? value.split(',') : value});
        setAssignedTo(
            typeof value === 'string' ? value.split(',') : value
        );
    };


    const handleSubmit = () => {
        //put your validation logic here
        onSubmit(values);
        onClose();
    };



    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Order</DialogTitle>
            <Divider />
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {CreateColumns.map((column) => (

                            (column.accessorKey === 'order_no')
                                ? <TextField
                                        label={column.header}
                                        key={column.accessorKey}
                                        name={column.accessorKey}
                                        select
                                        onChange={(e) => {
                                            setValues({ ...values, [e.target.name]: e.target.value.toLocaleUpperCase() });
                                        }}>

                                        {orderNrPrefix.map((prefix) => (
                                            <MenuItem key={prefix} value={prefix}>
                                                {prefix}
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                : (column.accessorKey === 'order_date')
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker 
                                            key={column.accessorKey} 
                                            label={column.header}  
                                            onChange={(e) => {
                                                setValues({ ...values, ['order_date']: e});
                                            }}/>
                                            
                                    </LocalizationProvider>
                                : (column.accessorKey === 'first_delivery')
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            key={column.accessorKey}
                                            label={column.header}
                                            onChange={(e) => {
                                                setValues({ ...values, ['first_delivery']: e });
                                            }} />

                                    </LocalizationProvider>
                                : (column.accessorKey === 'final_delivery')
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            key={column.accessorKey}
                                            label={column.header}
                                            onChange={(e) => {
                                                setValues({ ...values, ['final_delivery']: e });
                                            }} />

                                    </LocalizationProvider>
                                : (column.accessorKey === 'invoice_date')
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            key={column.accessorKey}
                                            label={column.header}
                                            onChange={(e) => {
                                                setValues({ ...values, ['invoice_date']: e });
                                            }} />

                                    </LocalizationProvider>
                                : (column.accessorKey === 'payment_date')
                                    ? <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                key={column.accessorKey}
                                                label={column.header}
                                                onChange={(e) => {
                                                    setValues({ ...values, ['payment_date']: e });
                                                }} />

                                        </LocalizationProvider>
                                : (column.accessorKey === 'assigned_to')
                                    ? <Select
                                        labelId="demo-multiple-chip-label"
                                        label={column.header}
                                        key={column.accessorKey}
                                        id="demo-multiple-chip"
                                        multiple
                                        value={assignedTo}
                                        onChange={handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >

                                        <MenuItem disabled value="">
                                            <em>Assign User</em>
                                        </MenuItem>
                                        {users.map((user) => (
                                            <MenuItem
                                                key={user.name}
                                                value={user.name}
                                                style={getStyles(user.name, assignedTo, theme)}
                                            >
                                                {user.name}({user.id})
                                            </MenuItem>
                                        ))}
                                    </Select>

                                // : (column.accessorKey === 'status')
                                //     ? <TextField
                                //             select
                                //             label={column.header}
                                //             key={column.accessorKey}
                                //             onChange={(e) => {
                                //                 setValues({ ...values, ['status']: e.target.value});
                                //             }}
                                //     >
                                        
                                //         {status.map((stat) => (
                                //             <MenuItem
                                //                 key={stat}
                                //                 value={stat}
                                //             >
                                //                 {stat}
                                //             </MenuItem>
                                //         ))}

                                //     </TextField>

                                : <TextField
                                        key={column.accessorKey}
                                        label={column.header}
                                        name={column.accessorKey}
                                        onChange={(e) =>
                                            setValues({ ...values, [e.target.name]: e.target.value })
                                        }
                                    />
                        ))}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Create New Order
                </Button>
            </DialogActions>
        </Dialog>
    );
};



export default OrdersTable;
