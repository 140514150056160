/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import Nav from "../Components/Nav";
import { Chart as ChartJS, registerables } from 'chart.js';

import {
    ChakraProvider,
    Box as CBox,
    SimpleGrid,
    GridItem,
} from '@chakra-ui/react'

import { State } from '../interfaces/State';
import { api_host } from '../global_vars/api_host';
import OrdersAssignedTable from '../Components/OrdersAssignedTable';
import "../Scss/admin-dashboard.scss";
import SimpleSidebar from '../Components/SideBar';
import TotalOrdersAnalytics from '../Components/TotalOrdersAnalytics';
import DoughnutDoneOngoing from '../Components/DoughnutDoneOngoing';
import TotalUsersAnalytics from '../Components/TotalUsersAnalytics';
import UserDoneOrders from '../Components/UserDoneOrders';


ChartJS.register(...registerables);


// import ColumnSorter from '../Components/ColumnSorter';

const Dashboard = () => {
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [role, setRole] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState<any>();


    
    const history = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${api_host}/token`);
            setToken(response.data.accessToken);
            const decoded: any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        } catch (error: any) {
            if (error.response) {
                history("/");
            }
        }
    }


    useEffect(() => {
        refreshToken();
    },[]);



    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${api_host}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded:any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    

    let state: State = {
        showSuperAdminBoard: false,
        showUserBoard: false,
    }

    if (role) {
        state = {
            showSuperAdminBoard: role === 'super-admin',
            showUserBoard: role === 'user',
        };
    }


    return (

        <ChakraProvider>

            {state.showSuperAdminBoard && (
                <SimpleSidebar>

                    <Nav id={id} name={name} role={role} />

                      
                        <SimpleGrid mt={5} minChildWidth={250} spacing={50} className="dashboard-row">
                            
                           <GridItem className="dashboard-box">  
                            <DoughnutDoneOngoing />
                           </GridItem>
                          <GridItem className="dashboard-box" >
                            <TotalOrdersAnalytics />
                          </GridItem>


                          <GridItem className="dashboard-box" >
                            <TotalUsersAnalytics />
                          </GridItem>

                          <GridItem className="dashboard-box" >
                            <UserDoneOrders id={id} />
                          </GridItem>
                        </SimpleGrid>

                        <CBox className="assigned-table dashboard-box">

                          <OrdersAssignedTable id={id} />
                        
                        </CBox> 




                    

                </SimpleSidebar>

            )}


            {state.showUserBoard && (

                <>
                    <Nav id={id} name={name} role={role} />
                    <SimpleGrid mt={5} minChildWidth={250} spacing={50} className="dashboard-row">
                        <GridItem className="dashboard-box" >
                            <UserDoneOrders id={id} />
                        </GridItem>
                    </SimpleGrid>
                    <CBox className="assigned-table dashboard-box">

                        <OrdersAssignedTable id={id} />

                    </CBox> 
                </>
            )}
            
            
        </ChakraProvider>

                
    )
}



export default Dashboard
