import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { api_host } from '../global_vars/api_host';

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Box,
} from '@chakra-ui/react';
import { FcAreaChart } from 'react-icons/fc';


function AverageOrderPaymentAnalytics(props){
  const [averageAmount, setAverageAmount] = useState('');

  useEffect(() => {
    getAverageAmount();
  }, []);

  const axiosJWT = axios.create();

  const getAverageAmount = async() => {
    try{
      const response = await axiosJWT.get(`${api_host}/analytics/average/payment`);

      setAverageAmount(response.data.count);
    }catch(error: any){
      console.error(error);
      return;
    }
  }

  return(
    <>
      <Stat>
        <Box display="flex" className="total-amount-title">
            <StatLabel fontSize={20}>Average payment per order</StatLabel> 
        </Box>
        <Box display="flex" style={{ flexDirection: 'row-reverse' }}>
          <FcAreaChart size={70} />
        </Box>
        <Box marginTop={-65} display="flex" className="total-amount-stat-number">
          <StatNumber marginLeft={3} fontSize={30}><b>${averageAmount}</b></StatNumber> 
        </Box>
        <Box mt={2} className="total-amount-help-text">
          <StatHelpText>
            <StatArrow type='increase'/>
            gross average income
          </StatHelpText>
        </Box>
      </Stat>
    </>
  );
}


export default AverageOrderPaymentAnalytics;
