import { Dayjs } from 'dayjs';


export type CreateOrder = {
    assign_to: number
    order_no: string,
    order_date: Dayjs,
    description: string,
    client: string,
    first_delivery: Dayjs,
    revisions: number,
    final_delivery: Dayjs,
    invoice_no: string,
    invoice_date: Dayjs,
    payment_date: Dayjs,
    amount: number,
    assigned_to: string[],
    status: string,
};


export const orderNrPrefix = [
    'GO',
    'PO'
]
