

import { isRouteErrorResponse, useRouteError, Link } from "react-router-dom";
import { Box, Heading, Text, Button, ChakraProvider } from "@chakra-ui/react";


{
  /*

    As the name implies, this is the page which is rendered if
  there has been an error in the application. For ex: Wrong adress entered/without permissions

  */
}


export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  if(isRouteErrorResponse(error)){
    return (
      <ChakraProvider>
        <Box 
          textAlign="center" 
          py={10} 
          px={6}>
            <Heading
              display="inline-block"
              as="h2"
              size="2xl"
              bgGradient="linear(to-r, teal.400, teal.600)"
              backgroundClip="text"
              >
              {error.status}
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
              {error.statusText}
            </Text>
            <Text color={'gray.500'} mb={6}>
              The page you're looking for does not seem to exist
            </Text>

            <Link to={"/tabula/dashboard"}>
              <Button
                colorScheme="teal"
                bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                color="white"
                variant="solid">
                Go to Home
              </Button>
            </Link>
          </Box>
        </ChakraProvider>
      );
  }

  return(
    <div>
        Oops
    </div>
  );
}

