import { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { api_host } from '../global_vars/api_host';



import {
    Center,
    ChakraProvider,
    Box as CBox,
} from '@chakra-ui/react'

import MaterialReactTable, {
    MRT_ColumnDef
} from 'material-react-table';
import { NeedyCustomer } from '../interfaces/NeedyCustomer';
import { ThemeProvider, createTheme, } from '@mui/material';


function NeedyCustomersAnalytics(props){
    const [needy, setNeedy] = useState<NeedyCustomer[]>([]);

    useEffect(() => {
        getNeedy();
    }, []);

    const axiosJWT = axios.create();

    const getNeedy = async (): Promise<any> => {
        const response = await axiosJWT.get(`${api_host}/analytics/needy`);

        setNeedy(response.data);
    }

    const columns = useMemo<MRT_ColumnDef<NeedyCustomer>[]>(
        () => [
            {
                accessorKey: 'client',
                header: 'Client',
            },
            {
                accessorKey: 'count',
                header: 'No. of revisions',
            },
            {
                accessorKey: 'average',
                header: 'Average revisions/order',
            },
        ],
        [],
    );

    const muiTheme = createTheme({
        palette: {
            mode: 'dark',
            background:{
                default: 'rgb(26, 36, 51)',
            },
        },
    });

    return (
        <>
            <ChakraProvider>
                <ThemeProvider theme={muiTheme}>
                    <Center>
                        <CBox w="100%" zIndex={0}>
                            <MaterialReactTable
                                columns={columns}
                                data={needy}
                                initialState={{ density: 'compact' }}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableGlobalFilter={false}
                                enableHiding={false}
                                enableColumnFilters={false}
                                enableRowNumbers={true}
                            />
                        </CBox>
                    </Center>
                </ThemeProvider>
            </ChakraProvider>
        </>
    );
}


export default NeedyCustomersAnalytics;