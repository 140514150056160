import React, { useState, useEffect, } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { api_host } from '../global_vars/api_host';

import axios from 'axios';

import {
  Box as CBox
} from '@chakra-ui/react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



export const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: 'Line chart for orders and their payments / month',
    },
  }, 
  scales: {
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', "November", "December"];


function OrdersMultiAxisChartAnalytics(props){
  const [paymentAmount, setPaymentAmount] = useState<number[]>([]);
  const [totalOrders, setTotalOrders] = useState<number[]>([]);

  const data = {
  labels,
  datasets: [
    {
      label: 'Payment amount',
      data: paymentAmount,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      yAxisID: 'y',
    },
    {
      label: 'Total Orders',
      data: totalOrders, 
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
      yAxisID: 'y1',
    }, 
  ],
};

  useEffect(() => {
    getAnalyticsData();
  }, []);

  const axiosJWT = axios.create();

  const getAnalyticsData = async () => {
    const response = await axiosJWT.get(`${api_host}/analytics/months`);
    
    setPaymentAmount(response.data.payment_amount);
    setTotalOrders(response.data.total_projects);
  }

  return(
    <CBox w={['100%', '100%', '100%']} h={[300, 400, 500]}>
      <Line options={options} data={data} />
    </CBox>
  );
}


export default OrdersMultiAxisChartAnalytics;
