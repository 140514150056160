

import { useRef, MutableRefObject, useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Center,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  Input,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';
import super_admin_gradient from '../Gradients/super-admin';
import user_gradient from '../Gradients/user';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api_host } from '../global_vars/api_host';





{
  /*

    @This whole page has to show user data based on what the database returns

  */
}




// interface picPath{
//   path: string;
// };

export default function Nav(props: any) {

  const id = props.id;
  const name = props.name;
  const role = props.role;

  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [token, setToken] = useState('');
  // const [expire, setExpire] = useState<any>();
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [passMsg, setPassMsg] = useState('');
  const cancelRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const navigate = useNavigate();


  const handleNewPassChange = (e: any) => setNewPass(e.target.value);
  const handleOldPassChange = (e: any) => setOldPass(e.target.value);
  const isErrorNewPass = newPass === '';
  const isErrorOldPass = oldPass === '';

  const Logout = async () => {
      try{
        await axios.delete(`${api_host}/logout`);
        navigate("/");
      }catch(error: any){
        console.log(error);
      }
  }

  // useEffect(() => {
  //   refreshToken();
  // }, []);

  // const refreshToken = async () => {
  //   try{
  //     const response = await axios.get('${api_host}/token');
  //     setToken(response.data.accessToken);
  //     const decoded: any = jwt_decode(response.data.accessToken);
  //     setExpire(decoded.expire);
  //   }catch(error: any){
  //     if(error.response){
  //       navigate("/");
  //     }
  //   }
  // }

  // const axiosJWT = axios.create();

  // axiosJWT.interceptors.request.use(async (config) => {
  //   const currentDate = new Date();
  //   if (expire * 1000 < currentDate.getTime()) {
  //     const response = await axios.get('${api_host}/token');
  //     config.headers.Authorization = `Bearer ${response.data.accessToken}`;
  //     setToken(response.data.accessToken);
  //     const decoded: any = jwt_decode(response.data.accessToken);
  //     setExpire(decoded.exp);
  //   }
  //   return config;
  // }, (error) => {
  //   return Promise.reject(error);
  // });


  const changePass = async () => {
    try{
      await axios.put(`${api_host}/change/pass`, {
          oldPassword: oldPass,
          newPassword: newPass,
      });
      navigate("/");
    }catch(error: any){
      console.log(error);
      setPassMsg(error.response.data.msg);
    }
  }



  const handleClickDashboard = () => {
    try{
      navigate("/tabula/dashboard/");
    }catch(error:any){
      console.log(error);
    }
  }


  const handleClickAnalytics = () => {
    try{
      navigate("/tabula/dashboard/analytics");
    }catch(error:any){
      console.log(error);
    }
  }

  const userRoleGradient = props.role === 'super-admin' ? super_admin_gradient : user_gradient

  return (
    <>
      <Box zIndex={2} bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={20} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
          </Box>

          <Flex alignItems={'center'}>
            <Stack marginRight={10} direction={'row'} spacing={7}>



              <Menu>
                <Flex>
                  <Box marginTop={2} marginRight={6}>
                    <Text 
                      fontSize='md'
                      color='white'
                    >
                      {name}
                    </Text>

                    <Text 
                      fontSize='sm' 
                      color='gray.400'
                      bgGradient={userRoleGradient}
                      backgroundClip='text'
                      >
                      {role}
                    </Text>
                  </Box>
                  <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}
                    size='md'>
                    <Avatar
                      size={'md'}
                      src={'https://avatars.dicebear.com/api/male/username.svg'}
                    />
                  </MenuButton>
                </Flex>
                <MenuList alignItems={'center'}>
                  <br />
                  <Center>
                    <Avatar
                      size={'2xl'}
                      src={'https://avatars.dicebear.com/api/male/username.svg'}
                    />
                  </Center>
                  <br />
                  <Center fontSize={20}>
                    <p>{name} ({id})</p>
                  </Center>
                  <Center>
                    <Text
                    fontSize={14}
                    bgGradient={userRoleGradient}
                    backgroundClip='text'
                    fontWeight='bold'>
                      {role}
                      </Text>
                  </Center>

                  <br />
                  <MenuDivider />
                  {role === 'super-admin' && (
                    <>
                      <MenuItem><Button onClick={handleClickDashboard}>Your Dashboard</Button></MenuItem>
                      <MenuItem><Button onClick={handleClickAnalytics}>Site analytics</Button></MenuItem>
                    </>
                  )}

                  <MenuItem>
                    <Button onClick={Logout}>
                      Logout
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button onClick={onOpen}>
                      Change password
                    </Button>

                    <AlertDialog
                      motionPreset='slideInBottom'
                      isOpen={isOpen}
                      leastDestructiveRef={cancelRef}
                      onClose={onClose}
                      isCentered>
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                              Change Password
                            </AlertDialogHeader>

                            <AlertDialogBody fontWeight="bold">
                              Proceed with caution like you are being followed by a Karen. You can't undo this!
                              <br/>
                              <br/>

                            <p>
                              Old Password
                            </p>
                            <FormControl isInvalid={isErrorOldPass} id="oldpass-change">
                              <Input
                                placeholder="here's the password manager"
                                value={oldPass}
                                onChange={handleOldPassChange}
                                type="password" />
                              {!isErrorOldPass ? (
                                <FormHelperText>
                                  Enter your old password
                                </FormHelperText>
                              ) : (
                                <FormErrorMessage>
                                  Old password is required.
                                </FormErrorMessage>
                              )}

                              <br/>

                              <p>
                                New Password
                              </p>
                            </FormControl>
                              <FormControl isInvalid={isErrorNewPass} id="newpass-change">
                                <Input
                                  placeholder="here's the password manager"
                                  value={newPass}
                                  onChange={handleNewPassChange}
                                  type="password"/>
                                {!isErrorNewPass ? (
                                  <FormHelperText>
                                    Enter your new password
                                  </FormHelperText>
                                ) : (
                                  <FormErrorMessage>
                                    New password is required.
                                  </FormErrorMessage>
                                )}
                              </FormControl>
                              
                              <br/>

                              <Center>
                                {passMsg}
                              </Center>

                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button ref={cancelRef} onClick={onClose}>
                                  Cancel
                              </Button>
                              <Button colorScheme='red' ml={3} ref={cancelRef} onClick={changePass}>
                                  Change
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}