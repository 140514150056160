import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { api_host } from '../global_vars/api_host';

import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Center,
  Box,
} from '@chakra-ui/react';
import { FcRatings } from 'react-icons/fc';
import '../Scss/totalOrders.scss';

function TotalOrdersAnalytics(props){
  const [totalOrders, setTotalOrders] = useState('');

  useEffect(() => {
    getTotalOrders();
  }, []);

  const axiosJWT = axios.create();

  const getTotalOrders = async() => {
    try{
      const response = await axiosJWT.get(`${api_host}/orders/total`);

      setTotalOrders(response.data.count);
    }catch(error: any){
      console.error(error);
      return;
    }
  }

  return(
    
    <>
      <Center padding={75} textAlign='center'>
          <Stat>
            <StatLabel fontSize={40}>Total Orders</StatLabel>
            <Center fontSize={40}>
              <FcRatings />
            </Center>
            <br/>
            <Box className="total-orders-stat-number">
              <StatNumber>{totalOrders}</StatNumber>
            </Box>
            <br/>
            <Box className="total-orders-help-text">
              <StatHelpText>
                <StatArrow type='increase' />
                includes completed orders 
              </StatHelpText>
            </Box>
          </Stat>
      </Center>
    </>

  );
}


export default TotalOrdersAnalytics;
