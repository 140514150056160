import {
    Flex,
    SimpleGrid,
} from '@chakra-ui/react';

import {
    useNavigate,
    useLocation,
} from 'react-router-dom';


import {
    useEffect,
    useState,
} from 'react';


import axios from 'axios';
import jwt_decode from "jwt-decode";
import ProjectCard from '../Components/ProjectsCards';
import { ChakraProvider } from '@chakra-ui/provider';
import FirstLogin from '../Components/FirstLogin';
import { api_host } from '../global_vars/api_host';




export default function ProjectsListing(){

    const [token, setToken] = useState('');
    const [expire, setExpire] = useState<any>();
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [role, setRole] = useState('');
    const history = useNavigate();
    const [projects, setProjects] = useState<any[]>([]);
    const location = useLocation();

    
    useEffect(() => {
        refreshToken();
        getProjects();
    }, []);


    const refreshToken = async () => {
        try {
            const response = await axios.get(`${api_host}/token`);
            setToken(response.data.accessToken);
            const decoded: any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        } catch (error: any) {
            if (error.response) {
                history("/");
            }
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${api_host}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded: any = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setId(decoded.userId);
            setRole(decoded.role);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });


    const getProjects = async() => {
        const response = await axiosJWT.get(`${api_host}/projects`);
        setProjects(response.data);
    }

    return(
        <ChakraProvider>

            {location.state.newUser === true && (<FirstLogin name={name} role={role} />)}

            <Flex marginTop={40}>
                <SimpleGrid
                    columns={{ base: 1, xl: 2 }}
                    spacing={'20'}
                    mt={16}
                    mb={16}
                    mx={'auto'}>
                    {projects.map((info, index) => (
                        <ProjectCard {...info} index={index} />
                    ))}
                </SimpleGrid>
            </Flex>

        </ChakraProvider>
    );
}
