
import { useState, useEffect } from 'react';
import GetUserId from './GetUserId';
import { api_host } from '../global_vars/api_host';

import axios from 'axios';


function GetAssignedTo(props){
    const { order_id } = props;

    const [assignedToNames, setAssignedToNames] = useState<any[]>([]);

    useEffect(() => {
        getAssignedTo(order_id);
    }, []);

    const axiosJWT = axios.create();

    const getAssignedTo = async(order_id) => {
        if(order_id === null){
            return;
        }

        const response = await axiosJWT.get(`${api_host}/users/assigned/orders/?id=${order_id}`);

        setAssignedToNames(response.data);
    };


    const names_list = assignedToNames.map((name) => 
        <><GetUserId id={name.userId}/>, </>
    );

    return(
        <>
            {names_list}
        </>
    );
}


export default GetAssignedTo;