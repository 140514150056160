
import { useState, useEffect } from 'react';
import { api_host } from '../global_vars/api_host';


import axios from 'axios';

function GetUserId(props){
    const { id } = props;

    const [userIdName, setUserIdName] = useState('');

    useEffect(() => {
        getUserNameId(id);
    }, []);

    const axiosJWT = axios.create();

    const getUserNameId = async(id) => {
        if(id === null){
            return;
        }
        const response = await axiosJWT.get(`${api_host}/user/name/?id=${id}`);

        
        setUserIdName(response.data.name);
    }
    
    return(
        <>
            {userIdName}
        </>
    );
}


export default GetUserId;
