import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import App from "./App";
import Dashboard from './Pages/Main';
// import Register from './Pages/Register';
import ErrorPage from './Pages/error-page';
import ProjectsListing from "./Pages/ProjectsListing";
import ManageUsers from "./Pages/ManageUsers";
import ManageOrders from './Pages/ManageOrders';
import Analytics from './Pages/Analytics';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import theme from './theme';
import axios from 'axios';

axios.defaults.withCredentials = true;


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [

    ],
  },

  {
    path: "tabula/dashboard",
    element: <Dashboard />
  },

  {
    path: "tabula/dashboard/analytics",
    element: <Analytics />
  },

  {
    path: "tabula/dashboard/users",
    element: <ManageUsers />,
  },

  {
    path: "tabula/dashboard/orders",
    element: <ManageOrders />,
  },

  {
    path: "projects",
    element: <ProjectsListing />
  },



  // {
  //   path: "/register",
  //   element: <Register />
  // },
]);

const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
    <RouterProvider router={router}/>
  </>
)
