import '../../Scss/bestemployeecard.scss';
import { useState, useEffect } from 'react';

import {
    Heading,
    Box,
    Center,
    Image,
    Text,
    Stack,
    Button,
    useColorModeValue,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Grid, 
    GridItem
} from '@chakra-ui/react';

import EmployeeCardHaikei1 from '../../Assets/employee-card-haikei.svg';
import EmployeeCardHaikei2 from '../../Assets/employee-card-haikei-2.svg';
import EmployeeCardHaikei3 from '../../Assets/employee-card-haikei-3.svg';
import EmployeeCardHaikei4 from '../../Assets/employee-card-haikei-4.svg';
import EmployeeCardHaikei5 from '../../Assets/employee-card-haikei-5.svg';


const images = [
    EmployeeCardHaikei1,
    EmployeeCardHaikei2,
    EmployeeCardHaikei3,
    EmployeeCardHaikei4,
    EmployeeCardHaikei5,
];

export default function BestEmployeesCard(props){
    const { id, name, role, amount, orders } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const [currentImageIndex, setCurrentImageIndex] = useState(Math.floor(Math.random() * images.length))
    const changeImage = () => {
        const randomNumber = Math.floor(Math.random() * images.length);
        setCurrentImageIndex(randomNumber);
    }
    useEffect(() => changeImage(), [])


    return(
        <Box>
            <Center py={6}>
                <Box
                    maxW={'300px'}
                    w={'full'}
                    bg={useColorModeValue('white', 'gray.800')}
                    boxShadow={'dark-lg'}
                    rounded={'md'}
                    overflow={'hidden'}>
                    <Image
                        h={'120px'}
                        w={'full'}
                        src={
                            images[currentImageIndex]
                        }
                        objectFit={'cover'}
                    />

                    <Box p={6}>
                        <Stack spacing={0} align={'center'} mb={5}>
                            <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                                {name}
                            </Heading>
                            <Text color={'gray.500'}>{role}</Text>
                        </Stack>

                        <Stack direction={'row'} justify={'center'} spacing={6}>
                            <Stack spacing={0} align={'center'}>
                                <Text fontWeight={600}>${amount ? amount : 0}</Text>
                                <Text fontSize={'sm'} color={'gray.500'}>
                                    Revenue
                                </Text>
                            </Stack>
                            <Stack spacing={0} align={'center'}>
                                <Text fontWeight={600}>{orders ? orders : 0}</Text>
                                <Text fontSize={'sm'} color={'gray.500'}>
                                    Completed
                                </Text>
                            </Stack>
                        </Stack>

                        <Button
                            w={'full'}
                            mt={8}
                            bg={useColorModeValue('#151f21', 'gray.900')}
                            color={'white'}
                            rounded={'md'}
                            _hover={{
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg',
                            }}
                            onClick={onOpen}>
                            See more
                        </Button>

                        <Modal isOpen={isOpen} onClose={onClose} isCentered>
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>
                                    <Center>
                                        <Box
                                            maxW={'300px'}
                                            w={'full'}
                                            bg={useColorModeValue('white', 'gray.800')}
                                            boxShadow={'dark-lg'}
                                            rounded={'md'}
                                            overflow={'hidden'}>
                                            <Image
                                                h={'120px'}
                                                w={'full'}
                                                src={
                                                    images[currentImageIndex]
                                                }
                                                objectFit={'cover'}
                                            />

                                        </Box>

                                        
                                    </Center>
                                </ModalHeader>
                                <ModalCloseButton />
                                <ModalBody>
                                    <Stack spacing={0} align={'center'} mb={5}>
                                        <Heading fontSize={'2xl'} fontWeight={500} fontFamily={'body'}>
                                            {name}
                                        </Heading>
                                        <Text color={'gray.500'}>{role}</Text>
                                    </Stack>

                                    <Center>
                                        <Grid templateColumns='repeat(2, 2fr)' gap={10}>
                                            <GridItem>
                                                Revenue: ${amount ? amount : "0"}
                                            </GridItem>
                                            <GridItem>
                                                Completed orders: {orders}
                                            </GridItem>
                                            <GridItem>
                                                Avg. Rev: ${amount && orders ? Math.round(amount / orders) : "N/A"}
                                            </GridItem>
                                        </Grid>
                                    </Center>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                                        Close
                                    </Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Box>
                </Box>
            </Center>
        </Box>
    );
}
